import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ServerCommunicationService } from 'marketplace/frontend/src/app/services/serverCommunication.service';
import { POINT_TYPE, ROLE, IBulbiPointRoleProperties, ICustomerRoleProperties, IUser, PASSWORD_REGEX } from 'common-lib';
import { UserDetailsComponent } from '../user-details.component';
import { passwordEqualityValidator } from 'angular-staff';

@Component({
    selector: 'point-details',
    templateUrl: 'point-details.component.html',
    styleUrls: ['../user-details.component.scss'],
})
export class PointDetailsComponent extends UserDetailsComponent<IBulbiPointRoleProperties> {
    private customers: IUser<ICustomerRoleProperties>[];
    public customersList: string[] = [];
    public registerMode: boolean;
    public readonly POINT_TYPE: typeof POINT_TYPE = POINT_TYPE;
    constructor(route: ActivatedRoute, router: Router, location: Location, formBuilder: FormBuilder, communicationService: ServerCommunicationService) {
        super(ROLE.REMOTEPOINT, formBuilder, route, location, router, communicationService);
        this.form = this.formBuilder.group(
            {
                customer: ['', Validators.required],
                email: ['', [Validators.required, Validators.email]],
                type: ['', Validators.required],
                password: ['', [Validators.required, Validators.pattern(PASSWORD_REGEX)]],
                passwordConfirmation: ['', [Validators.required, Validators.pattern(PASSWORD_REGEX)]],
            },
            {
                validators: passwordEqualityValidator,
            }
        );
    }
    async buildForm(): Promise<void> {
        this.customers = await this.communicationService.sockets.UserSocket.getUsers<ICustomerRoleProperties>(ROLE.CUSTOMER);
        this.customersList = this.customers.map((c) => c.roleProperties.firstName + ' ' + c.roleProperties.lastName);
        const customer: IUser<ICustomerRoleProperties> = this.customers.find((c) => c._id === this.user.roleProperties.customerID)!;
        this.form.patchValue({
            customer: customer ? customer.roleProperties.firstName + ' ' + customer.roleProperties.lastName : '',
            email: this.user.username,
            type: this.user.roleProperties.type,
        });
        if (!this.registerMode) {
            this.form.disable();
        }
    }
    public fillRoleProps(rawFormValue: any): IBulbiPointRoleProperties {
        const customerID: string = this.customers.find((c) => c.roleProperties.firstName + ' ' + c.roleProperties.lastName === rawFormValue.customer)!._id as string;
        return {
            customerID: customerID,
            type: this.user.roleProperties.type,
        };
    }
}
