import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { IRemotePointSystemParams } from '../../../../../common/interfaces/remotePointSystemParams.interface';
import { CommonSystemParams, TestClassProvider, UPGRADE_FEATURES } from 'common-lib';

@Component({
    selector: 'point-overview',
    templateUrl: 'point-overview.component.html',
    styleUrls: ['point-overview.component.scss'],
    host: { class: 'scrollable' },
})
export class PointOverviewComponent implements OnInit, OnDestroy {
    public systemParams: IRemotePointSystemParams;
    private subscriptions: Array<Subscription> = [];
    constructor(public route: ActivatedRoute, public router: Router, private testsHelper: TestClassProvider) {}
    ngOnInit(): void {
        this.systemParams = this.route.snapshot.data['systemParams'];
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach((s) => s.unsubscribe());
    }
    public getFeatureName(feature: string): string {
        return UPGRADE_FEATURES[feature as keyof typeof UPGRADE_FEATURES];
    }
    public getBgColor(i: number, key: string): object {
        const current: CommonSystemParams = this.systemParams.systemParams[i];
        const previous: CommonSystemParams = this.systemParams.systemParams[i + 1];
        let color: string;
        if (current && previous) {
            if (key === 'supportEvent') {
                color = JSON.stringify(current.supportEvent?.upgradeFeatures.sort()) === JSON.stringify(previous.supportEvent?.upgradeFeatures.sort()) ? '#dce2e1' : '#f5b9a9';
            } else if (key === 'devicesSettings') {
                color = '#f5b9a9';
            } else {
                color = current[key as keyof CommonSystemParams] === previous[key as keyof CommonSystemParams] ? '#dce2e1' : '#f5b9a9';
            }
        } else {
            color = '#dce2e1';
        }
        return {
            color: color,
        };
    }
    public getdevicesSettingsDiff(i: number): string {
        const current: CommonSystemParams = this.systemParams.systemParams[i];
        const previous: CommonSystemParams = this.systemParams.systemParams[i + 1];

        if (!current?.devicesSettings || !previous?.devicesSettings) {
            return ''; // Return an empty string if either setting is missing
        }

        const changes: string[] = [];
        const prevMap = new Map(previous.devicesSettings.map((ds) => [ds.type, ds]));

        for (const currSetting of current.devicesSettings) {
            const prevSetting = prevMap.get(currSetting.type);

            if (prevSetting) {
                // Check if "isEnabled" has changed
                if (currSetting.isEnabled !== prevSetting.isEnabled) {
                    const status = currSetting.isEnabled ? 'enabled' : 'disabled';
                    changes.push(`Device ${currSetting.type === 'HAPLO' ? 'BulbiCAM' : currSetting.type} was ${status}`);
                }

                // Check for differences in the SHOWN array of TEST_TYPE items
                const currentShownTests = currSetting.additionalSettings?.testStages?.SHOWN || [];
                const previousShownTests = prevSetting.additionalSettings?.testStages?.SHOWN || [];

                const addedTests = currentShownTests.filter((test) => !previousShownTests.includes(test)).map((test) => this.testsHelper.getTestName(currSetting.type, test));
                const removedTests = previousShownTests.filter((test) => !currentShownTests.includes(test)).map((test) => this.testsHelper.getTestName(currSetting.type, test));

                if (addedTests.length || removedTests.length) {
                    const enabledTests = addedTests.length > 0 ? `enabled: ${addedTests.join(', ')}` : '';
                    const disabledTests = removedTests.length > 0 ? `disabled: ${removedTests.join(', ')}` : '';
                    const testsChange = [enabledTests, disabledTests].filter(Boolean).join(' and ');
                    changes.push(`Tests ${testsChange} for device ${currSetting.type === 'HAPLO' ? 'BulbiCAM' : currSetting.type}`);
                }
            }
        }

        return changes.join('; ') || ''; // Join changes with a semicolon for readability
    }
}
