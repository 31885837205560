<div class="buttons">
    <p>BCU serial number: {{ systemParams.systemParams[0]['BCU serial number'] }}</p>
    <button class="normalButton primaryButton" [routerLink]="['../all']">Back to BulbiHUB list</button>
    <button class="normalButton primaryButton" [routerLink]="['calibration-tests/SCREEN_TEST']">BulbiCAM calibration tests</button>
    <button
        class="normalButton primaryButton"
        (click)="
            this.router.navigate(['./devices-tests-state'], {
                relativeTo: this.route,
                state: { systemParams: this.systemParams.systemParams[0], pointID: this.systemParams.sourceID.toString() }
            })
        "
    >
        Tests state
    </button>
</div>
<div *ngIf="systemParams" class="table">
    <div class="header"><p>Edited at:</p></div>
    <div class="header"><p>BCAM SN</p></div>
    <div class="header"><p>Production officer</p></div>
    <div class="header"><p>First shipping address</p></div>
    <div class="header"><p>Support event</p></div>
    <div class="header"><p>Support SW</p></div>
    <div class="header"><p>IP ARD</p></div>
    <div class="header"><p>Location Country/City</p></div>
    <div class="header"><p>Vimba CAM SN</p></div>
    <div class="header"><p>PICO SN</p></div>
    <div class="header"><p>HUB image</p></div>
    <div class="header"><p>HUB SW</p></div>
    <div class="header"><p>CAM SW</p></div>
    <div class="header"><p>CAM FW</p></div>
    <div class="header"><p>nVidia Driver version</p></div>
    <div class="header"><p>BulbiCAM LOT number</p></div>
    <div class="header"><p>Devices/Tests (changes only)</p></div>
    <ng-container *ngFor="let systemParam of systemParams.systemParams; let i = index">
        <div class="cell">
            <p>{{ systemParam.editedAt | date : 'dd.MM.yy H:mm:ss' }}</p>
        </div>
        <div class="cell">
            <p [ngStyle]="getBgColor(i, 'BulbiCAM serial number')">{{ systemParam['BulbiCAM serial number'] }}</p>
        </div>
        <div class="cell">
            <p [ngStyle]="getBgColor(i, 'Production officer')">{{ systemParam['Production officer'] }}</p>
        </div>
        <div class="cell">
            <p [ngStyle]="getBgColor(i, 'First shipping address')">{{ systemParam['First shipping address'] }}</p>
        </div>
        <div class="cell">
            <small>{{ systemParam.supportEvent.supportOfficer }}</small>
            <small>{{ systemParam.supportEvent.event }}</small>
            @for (feature of systemParam.supportEvent.upgradeFeatures; track feature) {
            <small [ngStyle]="getBgColor(i, 'upgradeFeatures')">{{ getFeatureName(feature) }}</small>
            }
        </div>
        <div class="cell">
            <p [ngStyle]="getBgColor(i, 'Rustdesk ID')">{{ systemParam['Rustdesk ID'] }}</p>
        </div>
        <div class="cell">
            <p [ngStyle]="getBgColor(i, 'Production IP')">{{ systemParam['Production IP'] }}</p>
        </div>
        <div class="cell" style="grid-auto-flow: column">
            <p [ngStyle]="getBgColor(i, 'Country')">{{ systemParam.Country }}</p>
            @if (systemParam.Country && systemParam.City) {
            <p>/</p>
            }
            <p [ngStyle]="getBgColor(i, 'City')">{{ systemParam.City }}</p>
        </div>
        <div class="cell">
            <p [ngStyle]="getBgColor(i, 'Camera serial number')">{{ systemParam['Camera serial number'] }}</p>
        </div>
        <div class="cell">
            <p [ngStyle]="getBgColor(i, 'Main PCB serial number')">{{ systemParam['Main PCB serial number'] }}</p>
        </div>
        <div class="cell">
            <p [ngStyle]="getBgColor(i, 'BulbiHUB image')">{{ systemParam['BulbiHUB image'] }}</p>
        </div>
        <div class="cell">
            <p [ngStyle]="getBgColor(i, 'BulbiHUB version')">{{ systemParam['BulbiHUB version'] }}</p>
        </div>
        <div class="cell">
            <p [ngStyle]="getBgColor(i, 'BulbiCAM version')">{{ systemParam['BulbiCAM version'] }}</p>
        </div>
        <div class="cell">
            <p [ngStyle]="getBgColor(i, 'Camera firmware version')">{{ systemParam['Camera firmware version'] }}</p>
        </div>
        <div class="cell">
            <p [ngStyle]="getBgColor(i, 'nVidia driver version')">{{ systemParam['nVidia driver version'] }}</p>
        </div>
        <div class="cell">
            <p [ngStyle]="getBgColor(i, 'BulbiCAM LOT number')">{{ systemParam['BulbiCAM LOT number'] }}</p>
        </div>
        <div class="cell">
            <p [ngStyle]="getBgColor(i, 'devicesSettings')">{{ getdevicesSettingsDiff(i) }}</p>
        </div>
    </ng-container>
</div>
