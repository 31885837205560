<div class="buttons-container">
    <div class="quadrant top-left" [routerLink]="['/dashboard-manager/representatives']">
        <div class="circle">
            <p>Examinations and tests</p>
        </div>
        <div class="shape">
            <h2>Billing</h2>
        </div>
    </div>
    <div class="quadrant top-right" [routerLink]="['/dashboard-manager/points/all']">
        <div class="circle">
            <p>Tracking change records (SW, HW, repair, calibration, location etc)</p>
        </div>
        <div class="shape">
            <h2>All BCU</h2>
        </div>
    </div>
    <div class="quadrant bottom-left" [routerLink]="['/dashboard-manager/issues-list']">
        <div class="circle">
            <p>Screenshots and Issue descriptions</p>
        </div>
        <div class="shape">
            <h2>Shift F2 Reports</h2>
        </div>
    </div>
    <div class="quadrant bottom-right" [routerLink]="['/dashboard-manager/export']">
        <div class="circle">
            <p>Export of data from connected BCUs</p>
        </div>
        <div class="shape">
            <h2>Bulk Export</h2>
        </div>
    </div>
</div>
