import { DEVICE } from '../enum/device';
import { TEST_TYPE } from '../enum/test-type';
import { TEST_STAGE } from '../enum/testStage.enum';
import { Acuity4KidsBulbicamTest } from './tests/BulbiCAM/acuity4KidsBulbicamTest';
import { AntisaccadeBulbicamTest } from './tests/BulbiCAM/antisaccadeBulbicamTest';
import { ConvergenceBulbicamTest } from './tests/BulbiCAM/convergenceBulbicamTest';
import { DarkAdaptationAmdBulbicamTest } from './tests/BulbiCAM/darkAdaptationAmdBulbicamTest';
import { EyelidBulbicamTest } from './tests/BulbiCAM/eyelidBulbicamTest';
import { FixationBulbicamTest } from './tests/BulbiCAM/fixationBulbicamTest';
import { FunctionalScreeningBulbicamTest } from './tests/BulbiCAM/functionalScreeningBulbicamTest';
import { NineEyesBulbicamTest } from './tests/BulbiCAM/nineEyesBulbicamTest';
import { NystagmusEvaluationBulbicamTest } from './tests/BulbiCAM/nystagmusEvaluationBulbicamTest';
import { PtosisBulbicamTest } from './tests/BulbiCAM/ptosisBulbicamTest';
import { PupillaryEvaluation2BulbicamTest } from './tests/BulbiCAM/pupillaryEvaluation2BulbicamTest';
import { PursuitAndSaccadesBulbicamTest } from './tests/BulbiCAM/pursuitAndSaccadesBulbicamTest';
import { RecordingToolBulbicamTest } from './tests/BulbiCAM/recordingToolBulbicamTest';
import { SaccadeMergedBulbicamTest } from './tests/BulbiCAM/saccadeMergedBulbicamTest';
import { SaccadeBulbicamTest } from './tests/BulbiCAM/saccadesBulbicamTest';
import { SkewDeviationBulbicamTest } from './tests/BulbiCAM/skewDeviationBulbicamTest';
import { VisualFieldMergedBulbicamTest } from './tests/BulbiCAM/visualFieldMergedBulbicamTest';
import { ColorTest } from './tests/colorTest';
import { AntSegmentForus3NethraClassicTest } from './tests/FORUS/3NethraClassic/antSegmentForus3NethraClassicTest';
import { DryEyeForus3NethraClassicTest } from './tests/FORUS/3NethraClassic/dryEyeForus3NethraClassicTest';
import { PostSegmentForus3NethraClassicTest } from './tests/FORUS/3NethraClassic/postSegmentForus3NethraClassicTest';
import { AntSegmentHaagSlitlampTest } from './tests/HAAG/SLITLAMP/antSegmentHaagSlitlampTest';
import { ThreeDScanIvueOctTest } from './tests/IVUE/OCT/3dscanIvueOctTest';
import { ThreeDScanIvueOct_v3217Test } from './tests/IVUE/OCT/3dscanIvueOct_v3217Test';
import { PostSegmentNextsightNexyTest } from './tests/NEXTSIGHT/NEXY/postSegmentNextsightNexyTest';
import { PostSegmentNidekAfc330Test } from './tests/NIDEK/AFC330/postSegmentNidekAfc330Test';
import { AccommodationNidekArk1sTest } from './tests/NIDEK/ARK1S/accommodationNidekArk1sTest';
import { IpdGlareContrastNidekAcuityArk1sTest } from './tests/NIDEK/ARK1S/ipd-glare-contrast-acuityNidekArk1sTest';
import { KeratometryNidekArk1sTest } from './tests/NIDEK/ARK1S/keratometryNidekArk1sTest';
import { OcularRefractionNidekArk1sTest } from './tests/NIDEK/ARK1S/ocularRefractionNidekArk1sTest';
import { PupillaryEvaluationNidekArk1sTest } from './tests/NIDEK/ARK1S/pupillaryEvaluationNidekArk1sTest';
import { LensometryNidekLM7PTest } from './tests/NIDEK/LM7P/lensometryNidekLM7PTest';
import { AcaNidekNT530PTest } from './tests/NIDEK/NT530P/acaNidekNT530PTest';
import { PachymetryNidekNT530PTest } from './tests/NIDEK/NT530P/pachymetryNidekNT530PTest';
import { TonometryNidekNT530PTest } from './tests/NIDEK/NT530P/tonometryNidekNT530PTest';
import { MaculaScanNidekRs330Test } from './tests/NIDEK/RS330/maculaScanNidekRs330Test';
import { PostSegmentNidekRs330Test } from './tests/NIDEK/RS330/postSegmentNidekRs330Test';
import { IpdNidekTonorefIIITest } from './tests/NIDEK/TonorefIII/ipd-nidek-TonoferIIITest';
import { KeratometryTonorefIIITest } from './tests/NIDEK/TonorefIII/keratometryTonorefIIITest';
import { OcularRefractionTonorefIIITest } from './tests/NIDEK/TonorefIII/ocularRefractionTonorefIIITest';
import { PachymetryTonorefIIITest } from './tests/NIDEK/TonorefIII/pachymetryTonorefIITest';
import { PupillaryEvaluationTonorefIIITest } from './tests/NIDEK/TonorefIII/pupillaryEvaluationTonorefIIITest';
import { TonometryTonorefIIITest } from './tests/NIDEK/TonorefIII/tonometryTonorefIIITest';
import { AntSegmentTopconTritonTest } from './tests/TOPCON/TRITON/antSegmentTopconTritonTest';
import { MaculaScanTopconTritonTest } from './tests/TOPCON/TRITON/maculaScanTopconTritonTest';
import { PostSegmentTopconTritonTest } from './tests/TOPCON/TRITON/postSegmentTopconTritonTest';
import { WebCamImportsTest } from './tests/WebCamImportsTest';

export type TestHepler = {
    name: string;
    device: DEVICE;
    type: TEST_TYPE;
    stage: TEST_STAGE;
};
/**
 * This is helper class that could be used as source of classes represents base test-device entities
 */
export class TestClassProvider {
    public readonly testsProperties: TestHepler[];
    constructor() {
        this.testsProperties = [
            // CAM tests (abc order)
            new Acuity4KidsBulbicamTest(),
            new AntisaccadeBulbicamTest(),
            new ConvergenceBulbicamTest(),
            new DarkAdaptationAmdBulbicamTest(),
            new EyelidBulbicamTest(),
            new FixationBulbicamTest(),
            new FunctionalScreeningBulbicamTest(),
            new NineEyesBulbicamTest(),
            new NystagmusEvaluationBulbicamTest(),
            new PtosisBulbicamTest(),
            new PupillaryEvaluation2BulbicamTest(),
            new PursuitAndSaccadesBulbicamTest(),
            new RecordingToolBulbicamTest(),
            new SaccadeMergedBulbicamTest(),
            new SaccadeBulbicamTest(),
            new SkewDeviationBulbicamTest(),
            new VisualFieldMergedBulbicamTest(),
            // FORUS tests
            new AntSegmentForus3NethraClassicTest(),
            new DryEyeForus3NethraClassicTest(),
            new PostSegmentForus3NethraClassicTest(),
            // HAAG Slitlamp tests
            new AntSegmentHaagSlitlampTest(),
            // IVUE/OCT tests
            new ThreeDScanIvueOctTest(),
            new ThreeDScanIvueOct_v3217Test(),
            // NEXTSIGHT/NEXY tests
            new PostSegmentNextsightNexyTest(),
            new PostSegmentNidekAfc330Test(),
            new AccommodationNidekArk1sTest(),
            new IpdGlareContrastNidekAcuityArk1sTest(),
            new KeratometryNidekArk1sTest(),
            new OcularRefractionNidekArk1sTest(),
            new PupillaryEvaluationNidekArk1sTest(),
            new LensometryNidekLM7PTest(),
            new AcaNidekNT530PTest(),
            new PachymetryNidekNT530PTest(),
            new TonometryNidekNT530PTest(),
            new MaculaScanNidekRs330Test(),
            new PostSegmentNidekRs330Test(),
            new IpdNidekTonorefIIITest(),
            new KeratometryTonorefIIITest(),
            new OcularRefractionTonorefIIITest(),
            new PachymetryTonorefIIITest(),
            new PupillaryEvaluationTonorefIIITest(),
            new TonometryTonorefIIITest(),
            new AntSegmentTopconTritonTest(),
            new MaculaScanTopconTritonTest(),
            new PostSegmentTopconTritonTest(),
            new ColorTest(),
            new WebCamImportsTest(),
        ];
    }
    public getTestName(device: DEVICE, testType: TEST_TYPE): string | undefined {
        return this.testsProperties.find((t) => t.device === device && t.type === testType)?.name;
    }
    public getTestType(testName: string): TEST_TYPE | undefined {
        return this.testsProperties.find((t) => t.name === testName)?.type;
    }
    // Get tests by device
    public getTestsByDevice(device: DEVICE): TestHepler[] {
        return this.testsProperties.filter((p) => p.device === device);
    }
}
