import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { passwordEqualityValidator } from 'angular-staff';
import { ServerCommunicationService } from '../../services/serverCommunication.service';
import { PASSWORD_REGEX } from 'common-lib';

@Component({
    selector: 'update-password',
    templateUrl: 'update-password.component.html',
    styleUrls: ['update-password.component.scss'],
})
export class UpdatePasswordComponent implements OnInit {
    private restoreToken: string | null;
    public form: FormGroup;

    constructor(private route: ActivatedRoute, private router: Router, private formBuilder: FormBuilder, private serverCommunicationService: ServerCommunicationService) {
        this.form = this.formBuilder.group(
            {
                password: ['', [Validators.required, Validators.pattern(PASSWORD_REGEX)]],
                passwordConfirmation: ['', [Validators.required, Validators.pattern(PASSWORD_REGEX)]],
            },
            {
                validators: passwordEqualityValidator,
            }
        );
    }

    async ngOnInit() {
        try {
            this.restoreToken = this.route.snapshot.paramMap.get('token');
            if (!this.restoreToken) throw new Error('No token provided');
            await this.serverCommunicationService.sockets.UnsecuredSocket.connect();
            const message: string = await this.serverCommunicationService.sockets.UnsecuredSocket.isRestoreTokenValid(this.restoreToken);
            if (message) throw new Error(message);
        } catch (error: any) {
            alert(error.message?.toString() || error.toString());
            this.router.navigate(['/']);
        }
    }

    public async updatePassword(): Promise<void> {
        const newPassword: string = this.form.get('password')?.value;
        const message: string = await this.serverCommunicationService.sockets.UnsecuredSocket.updatePassword(this.restoreToken!, newPassword);
        alert(message);
        this.router.navigate(['/']);
    }
}
