import { BehaviorSubject, Subject } from 'rxjs';
import {
    CALIBRATION_TEST,
    CalibrationResultType,
    CommonSystemParams,
    IBulbicamTest,
    ICalibrationResult,
    ICommonCamMessage,
    IExaminationRepresentative,
    TEST_TYPE,
} from 'common-lib';
import { ITestsState } from '../../../../../common/interfaces/testsState';
import { IOSocket } from './ioSocket';
import { IRemotePointSystemParams } from '../../../../../common/interfaces/remotePointSystemParams.interface';
import { Manager } from 'socket.io-client';
import { checkSocketPromise } from '../decorators/checkSocket.decorator';

export class RemotePointSocket extends IOSocket {
    public pointsOnline: BehaviorSubject<string[]>;
    constructor(manager: Manager, socketError: Subject<Error>) {
        super(manager, '/remote-point', socketError);
        this.pointsOnline = new BehaviorSubject<string[]>([]);
    }
    @checkSocketPromise()
    public async addHendlers(resolvable: () => void): Promise<void> {
        this._socket.on('pointsOnlineList', (points: string[]) => {
            this.pointsOnline.next(points);
        });
    }
    @checkSocketPromise()
    public getTestsStates(pointID: string): Promise<ITestsState[]> {
        return new Promise<ITestsState[]>((res) => {
            this._socket.emit('getTestsStates', pointID, res);
        });
    }
    @checkSocketPromise()
    public eraseRepresentatives(pointID: string): Promise<void> {
        return new Promise<void>((res) => {
            this._socket.emit('erase_synchronization_mark', pointID, res());
        });
    }
    @checkSocketPromise()
    public sendSynchronationMessage(pointID: string): void {
        this._socket.emit('synchronize', pointID);
    }
    @checkSocketPromise()
    public async fetchCharts(representative: IExaminationRepresentative): Promise<{ testType: TEST_TYPE; charts: IBulbicamTest<ICommonCamMessage>[] }[]> {
        return new Promise<{ testType: TEST_TYPE; charts: IBulbicamTest<ICommonCamMessage>[] }[]>((res) => {
            this._socket.emit('fetchCharts', representative, res);
        });
    }
    @checkSocketPromise()
    public async fetchChartData(testType: TEST_TYPE, ts: number, pointId: string, haplotestId: string): Promise<void> {
        return new Promise<void>((res) => {
            this._socket.emit('fetchChartData', testType, ts, pointId, haplotestId, res);
        });
    }
    @checkSocketPromise()
    public async deleteChartData(testType: TEST_TYPE, ts: number): Promise<void> {
        return new Promise<void>((res) => {
            this._socket.emit('deleteChartData', testType, ts, res);
        });
    }
    @checkSocketPromise()
    public async showExistingChartData(): Promise<{ testType: TEST_TYPE; createdAt: number[] }[]> {
        return new Promise<{ testType: TEST_TYPE; createdAt: number[] }[]>((res) => {
            this._socket.emit('showExistingChartData', res);
        });
    }
    @checkSocketPromise()
    public async uploadChartData(filename: string, data: string): Promise<void> {
        return new Promise<void>((res) => {
            this._socket.emit('uploadChartData', filename, data, res);
        });
    }
    @checkSocketPromise()
    public async getPointSettings(pointID: string): Promise<IRemotePointSystemParams> {
        return new Promise<IRemotePointSystemParams>((res, rej) => {
            this._socket.emit('getPointSettings', pointID, res);
        });
    }
    @checkSocketPromise()
    public async setPointSettings(pointID: string, newSystemParams: CommonSystemParams): Promise<void> {
        return new Promise<void>((res, rej) => {
            this._socket.emit('setPointSettings', newSystemParams, pointID, res);
        });
    }
    @checkSocketPromise()
    public async getCalibrationTests<T extends CalibrationResultType>(pointID: string, types: CALIBRATION_TEST[]): Promise<ICalibrationResult<T>[]> {
        return new Promise<ICalibrationResult<T>[]>((res) => {
            this._socket.emit('getCalibrationTests', pointID, types, res);
        });
    }
    // public getProductionSettings(pointID: string): Observable<string | IRemotePointProductionSettings[]> {
    //     return new Observable<string | IRemotePointProductionSettings[]>(observer => {
    //         const readableStream = socketIOStream.createStream();
    //         socketIOStream(this._socket).emit('getProductionSettings', pointID, readableStream);
    //         observer.next('Upload started.');
    //         let totalBytesRead = 0;
    //         let totalBytesExpected = 0;
    //         let metadata: {
    //             _id: string;
    //             length: number;
    //             chunkSize: number;
    //             uploadDate: number;
    //             filename: string;
    //             md5: string;
    //             metadata: {
    //                 contentType: string;
    //                 createdAt: number;
    //             };
    //             buffer: Buffer;
    //         }[] = [];

    //         let chunks = [];

    //         readableStream.on('data', (chunk: Buffer) => {
    //             if (totalBytesExpected === 0) {
    //                 metadata = JSON.parse(chunk.toString());
    //                 metadata.forEach(f => (f['buffer'] = Buffer.alloc(0)));
    //                 totalBytesExpected = metadata.reduce((partialSum, acc) => partialSum + acc.length, 0);
    //             } else {
    //                 totalBytesRead += chunk.length;
    //                 const receivedMb = (totalBytesRead / 1024 / 1024).toFixed(2);
    //                 const expectedMb = (totalBytesExpected / 1024 / 1024).toFixed(2);
    //                 const progress = ((totalBytesRead * 100) / totalBytesExpected).toFixed(2);
    //                 observer.next(`Received ${receivedMb} mb of ${expectedMb} mb. ${progress} %`);
    //                 chunks.push(chunk);
    //                 // // Fill buffer of metadata objects
    //                 // let i = 0;
    //                 // while (i < metadata.length && chunk.length > 0) {
    //                 //     const remainingBytes = metadata[i].length - metadata[i].buffer.length;
    //                 //     const bufferChunk = chunk.slice(0, remainingBytes);
    //                 //     metadata[i].buffer = Buffer.concat([metadata[i].buffer, bufferChunk]);
    //                 //     chunk = chunk.slice(remainingBytes);
    //                 //     i++;
    //                 // }
    //                 // metadata.find(f => f.length < )
    //             }
    //         });
    //         readableStream.on('end', () => {
    //             // const buffer = Buffer.concat(chunks);
    //             // let processedBytes = 0;
    //             // metadata.forEach(f => {
    //             //     f.buffer = buffer.slice(processedBytes, f.length);
    //             //     // console.log(processedBytes, f.length);

    //             //     // const qqq = f.buffer.toString();
    //             //     // JSON.parse(qqq);
    //             //     // console.log(qqq.slice(0, 20), '<<>>', qqq.slice(-20), f.length, f.buffer.length);

    //             //     // buffer.copy(buffer, processedBytes, f.length);
    //             //     processedBytes += f.length;
    //             //     // console.log(buffer.length);
    //             // });

    //             // metadata.forEach(function (f) {
    //             //     const qqq = f.buffer.toString();
    //             //     console.log(qqq.slice(0, 20), '<<>>', qqq.slice(-20), f.length, f.buffer.length);
    //             // });
    //             // console.log(buffer.length);

    //             const buffer1 = Buffer.concat(chunks);
    //             // const qwe1 = buffer1.slice(0, buffer1.length / 2 + 2);
    //             const qwe1 = buffer1.slice(0, metadata[0].length);
    //             const qwe3 = qwe1.toString();
    //             console.log(qwe3.slice(0, 20), '<<>>', qwe3.slice(-20), qwe1.length, qwe3.length, metadata[0].length);
    //             const awe1 = buffer1.slice(metadata[0].length);
    //             const awe3 = awe1.toString();
    //             console.log(awe3.slice(0, 20), '<<>>', awe3.slice(-20), awe1.length, awe3.length, metadata[1].length);
    //             // const dwe1 = buffer1.slice(metadata[1].length);
    //             // const dwe3 = dwe1.toString();
    //             // console.log(dwe3.slice(0, 20), '<<>>', dwe3.slice(-20), awe1.length, dwe3.length, metadata[1].length);

    //             const s1 = JSON.parse(qwe3);
    //             const s2 = JSON.parse(awe3);
    //             // const s3 = JSON.parse(dwe3);
    //             // observer.next([s1, s2, s3]);

    //             // observer.next(metadata.map(f => JSON.parse(f.buffer.toString())));
    //             observer.complete();
    //         });
    //         readableStream.on('error', error => {
    //             console.log(error);
    //             observer.error(error);
    //         });
    //     });
    // }
}
